import React from "react";
import { graphql } from "gatsby";

import { PrivateEquityPageTemplate } from "../templates";
import { Layout } from "../components";

const PrivateEquityPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  return (
    <Layout title={frontmatter.headerTitle}>
      <PrivateEquityPageTemplate {...frontmatter} />
    </Layout>
  );
};

export default PrivateEquityPage;

export const pageQuery = graphql`
  query PrivateEquityPageQuery {
    markdownRemark(
      frontmatter: { templateKey: { eq: "private-equity-page" } }
    ) {
      frontmatter {
        headerImage {
          cloudinaryMedia
          assetType
          deliveryType
          urlRemainder
          sourceURL
        }
        headerTitle
        headerBottomButton
        headerBottomButtonLink
        heroTitle
        features {
          icon {
            props {
              children {
                key
                props {
                  d
                  points
                  strokeWidth
                  strokeLinejoin
                  strokeLinecap
                  fillRule
                  clipRule
                }
                type
              }
              attr {
                fill
                stroke
                strokeLinecap
                strokeLinejoin
                strokeWidth
                viewBox
              }
            }
          }
          title
          body
        }
        partnerLogo {
          cloudinaryMedia
          assetType
          deliveryType
          urlRemainder
          sourceURL
        }
        partnerTitle
        partnerBody
        partnerButton
        partnerButtonLink
        videosTitle
        videos {
          title
          thumbnail {
            cloudinaryMedia
            assetType
            deliveryType
            urlRemainder
            sourceURL
          }
          video {
            cloudinaryMedia
            assetType
            deliveryType
            urlRemainder
            sourceURL
          }
        }
      }
    }
  }
`;
